<template>
    <!-- Services-->
    <section class="page-section" id="services">
        <div class="container px-4 px-lg-5">
            <h2 class="text-center mt-0">Un service tout en un</h2>
            <hr class="divider" />
            <div class="row gx-4 gx-lg-5">
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['far', 'handshake']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">1. Etablissons votre projet</h3>
                        <p class="text-muted mb-0">Après une phrase de compréhension de votre activité et de vos besoins, nous définissons ensemble vos objectifs et KPIs. Nous vous faisons ensuite des propositions de stratégies pour atteindre les objectifs fixés.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'tv']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">2. Expérience utilisateur et image</h3>
                        <p class="text-muted mb-0">Nous travaillons l'image de votre marque ou entreprise, créons une charte graphique puis travaillons les visuels et photographies. Nous établissons une expérience utilisateur permettant d'intégrer ces différents éléments et d'optimiser vos parcours utilisateur.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'wrench']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">3. Réalisation et développement du projet</h3>
                        <p class="text-muted mb-0">Nous nous occupons du développement technique de votre projet avec les technologies adaptées et modernes. La collaboration et la transparence restent primordiales tout au long du projet afin d'assurer la cohérence du rendu avec le cahier des charges.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'wifi']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">4. Hébergement et maintenance</h3>
                        <p class="text-muted mb-0">Nous vous proposons un service d'hébergement clé en main afin que vous n'ayez pas à vous soucier des aspects techniques. La maintenance et les évolutions du projet sont assurées.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="showcase mb-5 mt-5">
        <div class="container-fluid p-0">
            <div class="row g-0">
                <img class="col-lg-6 order-lg-2 text-white img-fluid" v-bind:src="require('../assets/ux.jpg')"/>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>L'Expérience Utilisateur (UX) : Plongez dans l'Excellence Digitale</h2>
                    <p class="lead mb-0">Chez R42, nous plaçons l'utilisateur au cœur de chaque décision de conception. Nous commençons par une analyse approfondie de votre public cible, comprenant ses besoins, motivations et préférences. Cette compréhension approfondie guide la création d'interfaces intuitives et agréables, favorisant une navigation sans effort vers le tunnel de conversion.</p>
                </div>
            </div>
            <div class="row g-0">
                <img id="anchor-dev" class="col-lg-6 text-white img-fluid" v-bind:src="require('../assets/bg-showcase-2.jpg')"/>
                <div class="col-lg-6 my-auto showcase-text">
                    <h2>Développement et réalisation</h2>
                    <p class="lead mb-0">Notre équipe passionnée met à votre disposition son expertise variée pour concrétiser vos idées et atteindre vos objectifs numériques. Nous sommes spécialisés dans le développement de sites web dynamiques et intuitifs, ainsi que dans la création d'applications digitales sur mesure. En choisissant R42, vous optez pour une approche créative, une qualité de développement irréprochable, et une collaboration transparente tout au long du processus. Transformons ensemble votre vision digitale en réalité !</p>
                    <ul class="list-inline dev-icons">
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'html5']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'css3-alt']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'bootstrap']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'square-js']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'vuejs']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'node-js']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'symfony']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'php']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'wordpress']" />
                        </li>
                        <li class="list-inline-item">
                            <font-awesome-icon :icon="['fab', 'npm']" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row g-0">
                <img id="anchor-host" class="col-lg-6 order-lg-2 text-white img-fluid" v-bind:src="require('../assets/bg-showcase-3.jpg')"/>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Hébergement et maintenance</h2>
                    <p class="lead mb-0">Nous proposons la prise en charge complète de l'hébergement et de la maintenance pour votre site web. Vous bénéficiez de performances optimales, d'une sécurité renforcée et d'une disponibilité sans interruption. Notre équipe dévouée assure une surveillance proactive, des mises à jour régulières et une résolution rapide des problèmes potentiels, permettant à votre site de fonctionner de manière transparente 24 heures sur 24, 7 jours sur 7. Vous pouvez ainsi vous concentrer pleinement sur le développement de votre activité, en toute tranquillité d'esprit.</p>
                </div>
            </div>
            <div class="row g-0">
                <img id="anchor-security" class="col-lg-6 text-white img-fluid" v-bind:src="require('../assets/security.jpg')"/>
                <div class="col-lg-6 my-auto showcase-text">
                    <h2>Sécurité des données et fiabilité digitale</h2>
                    <p class="lead mb-0">Nous nous efforçons de maintenir des normes élevées de professionnalisme et d'efficacité pour assurer des solutions digitales sûres et fiables. Votre confiance est notre priorité, et nous sommes déterminés à créer un environnement digital sécurisé et robuste pour sécuriser vos données et celles de vos clients.</p>
                </div>
            </div>
            <div class="row g-0">
                <img class="col-lg-6 order-lg-2 text-white img-fluid" v-bind:src="require('../assets/seo.jpg')"/>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Référencement SEO</h2>
                    <p class="lead mb-0">Nous transformons les défis SEO en opportunités de croissance, améliorant le référencement de votre entreprise dans les résultats de recherche et assurant une expérience en ligne exceptionnelle pour vos visiteurs. Nous optimisons la structure technique de votre site, la vitesse de chargement et la convivialité mobile. Sur le plan sémantique, nous vous accompagnons dans la création de contenus riches, pertinents et engageants, tout en maximisant l'utilisation de mots-clés stratégiques.</p>
                </div>
            </div>
            <div class="row g-0">
                <img id="anchor-image" class="col-lg-6 text-white img-fluid" v-bind:src="require('../assets/photographie.jpg')"/>
                <div class="col-lg-6 my-auto showcase-text">
                    <h2>Photographie et identité visuelle</h2>
                    <p class="lead mb-0">Nous vous offrons une solution complète de création visuelle et de prise de photographie, sur mesure pour votre activité. Capturant l'essence même de votre entreprise, nos visuels uniques serviront de pierre angulaire à votre stratégie d'image et de communication. Que ce soit à travers des illustrations percutantes de vos produits, des portraits authentiques de votre équipe, ou des instantanés dynamiques de votre quotidien professionnel, nous créons ensemble des visuels qui reflètent fidèlement votre identité.</p>
                </div>
            </div>
            <div class="row g-0">
                <img id="anchor-gestion" class="col-lg-6 order-lg-2 text-white img-fluid" v-bind:src="require('../assets/agile.jpg')"/>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Gestion de projet & Méthodologies agiles</h2>
                    <p class="lead mb-0">Grâce à notre approche axée sur la collaboration, la transparence et l'adaptabilité, nous apportons des résultats à chaque étape du processus en évitant les écueils habituels des projets informatiques. Du concept à la réalisation, nous sommes là pour transformer vos idées en solutions digitales innovantes. </p>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.page-section {
  padding: 8rem 0;
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #1363C6;
  opacity: 1;
}

.text-pimary-blue {
    color: #1363C6;
}

.showcase .showcase-text {
  padding: 3rem;
}
.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}
@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.dev-icons {
  font-size: 3rem;
  color: #6c757d;
}
</style>