<template>
    <!-- Services-->
    <section class="page-section" id="services">
        <div class="container px-4 px-lg-5">
            <h2 class="text-center mt-0">Nos valeurs vous accompagnent</h2>
            <hr class="divider" />
            <div class="row gx-4 gx-lg-5">
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['far', 'face-smile']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Satisfaction client</h3>
                        <p class="text-muted mb-0">La satisfaction client est notre règle numéro 1. Elle conditionne tous nos projets et nos méthodologies de travail. Nous nous adaptons à votre entreprise, votre activité et vos besoins afin de garantir une prestation de qualité.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['far', 'handshake']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Accompagnement</h3>
                        <p class="text-muted mb-0">Nos méthodologies reposent sur un accompagnement au plus près de nos clients. Une communication fluide et transparente, une compréhension fine du métier et une prise en main de la stratégie digitale de bout en bout. Vous ne serez plus seul dans le monde digital !</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'user-shield']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Sécurité</h3>
                        <p class="text-muted mb-0">Avec l'importance des données dans notre société digitale, nous accordons une grande importance à la sécurité de nos projets, de nos clients et de leurs données. Nos processus d'échanges, de travail et normes techniques vont systématiquement dans ce sens.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'magnifying-glass']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Transparence</h3>
                        <p class="text-muted mb-0">Il n'est rien de plus désagréable que de devoir passer en revue le travail d'un partenaire, c'est pourquoi nous mettons un point d'honneur à être transparents en toutes circonstances. Du devis aux fonctionnement du projet, ainsi qu'avec les délais, nous nous efforçons de maintenir une communication transparente et constante.</p>
                    </div>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'hand-holding-dollar']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Prix justes</h3>
                        <p class="text-muted mb-0">Notre vision d'entreprise se place dans le temps long, avec des collaborations durables. C'est pourquoi nous cherchons toujours à adapter les projets aux profils de nos clients, afin d'optimiser les coûts et permettre une satisfaction commune, garante d'un partenariat durable.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="mt-5">
                        <div class="mb-2"><font-awesome-icon :icon="['fas', 'earth-americas']" size="3x" class="text-pimary-blue"/></div>
                        <h3 class="h4 mb-2">Informatique responsable</h3>
                        <p class="text-muted mb-0">Conscients des enjeux de notre temps, nous nous efforçons d'optimiser l'emprunte de nos projets lorsque cela est possible et n'entre pas en contradiction avec nos autres valeurs.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.page-section {
  padding: 8rem 0;
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #1363C6;
  opacity: 1;
}

.text-pimary-blue {
    color: #1363C6;
}
</style>