<template>
    <!-- Team Start -->
    <div class="container-fluid team-bg py-5">
        <div class="container py-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <h1 class="mb-4">Découvrez notre équipe</h1>
                    <p class="mb-4">Composée de membres expérimentés dans les domaines du digital, notre équipe vous aide à définir vos objectifs et établir vos KPIs.
                        Elle vous conseille ou prend en charge vos projets digitaux, venez découvrir comment nous pouvons vous accompagner.</p>
                    <router-link class="btn btn-primary rounded-pill px-4" to="/apropos">En savoir plus</router-link>
                </div>
                <div class="col-lg-7">
                    <div class="row g-4">
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="team-item bg-white text-center rounded p-4 pt-0">
                                        <img class="img-fluid rounded-circle p-4" v-bind:src="require('../assets/portraitJLB.jpg')"/>
                                        <h5 class="mb-0">Jean-Loup Becquet</h5>
                                        <small>Co Founder & CEO</small>
                                        <div class="d-flex justify-content-center mt-3">
                                            <!--
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'facebook-f']" />
                                            </a>
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'twitter']" />
                                            </a>
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'instagram']" />
                                            </a>
                                            -->
                                            <a class="btn btn-square btn-primary m-1" href="https://www.linkedin.com/in/jean-loup-becquet/">
                                                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pt-md-4">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="team-item bg-white text-center rounded p-4 pt-0">
                                        <img class="img-fluid rounded-circle p-4" v-bind:src="require('../assets/portraitPT.jpg')"/>
                                        <h5 class="mb-0">Philippe Teixeira</h5>
                                        <small>Co Founder & President</small>
                                        <div class="d-flex justify-content-center mt-3">
                                            <!--
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'facebook-f']" />
                                            </a>
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'twitter']" />
                                            </a>
                                            <a class="btn btn-square btn-primary m-1" href="">
                                                <font-awesome-icon :icon="['fab', 'instagram']" />
                                            </a>
                                            -->
                                            <a class="btn btn-square btn-primary m-1" href="https://www.linkedin.com/in/philippeteixeira/">
                                                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Team End -->
</template>

<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}

.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.text-primary-blue {
    color: #1363C6;
}

.team-bg {
    background: #F4F7FE;
}

.team-item {
    transition: .5s;
    border: 1px solid transparent;
    transition: .5s;
}

.team-item:hover {
    border-color: #1363C6;
}
</style>