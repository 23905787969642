<template>
    <!-- Hero Start -->
    <div class="container-fluid pt-5 hero-header mb-5">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <div class="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">R42.Tech</div>
                    <h1 class="display-4 text-white mb-4 animated slideInRight">Agence digitale aux idées innovantes</h1>
                    <p class="text-white mb-4 animated slideInRight">R42 accompagne ses clients dans leur stratégie digitale grâce à ses compétences cross-domaines. Création de site internet, expérience utilisateur (UX), tunnels de conversion, référencement (SEO), sécurité et surtout : satisfaction client !</p>
                    <router-link class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight" to="/apropos">En savoir plus</router-link>
                    <router-link class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight" to="/contact">Nous contacter</router-link>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid" v-bind:src="require('../assets/call-center-reverse.png')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** Hero Header ***/
.hero-header {
    margin-top: -75px;
    background: url(../assets/bg-hero.png) center center no-repeat;
    background-size: cover;
    background-color: #1363C6;
}

.hero-header .breadcrumb-item+.breadcrumb-item::before {
    color: #F4F7FE;
}
</style>