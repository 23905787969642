<template>
    <!-- Hero Start -->
    <div class="container-fluid pt-5 hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">{{ name }}</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center justify-content-lg-start mb-0">
                            <li class="breadcrumb-item">
                                <router-link class="text-white" to="/">Accueil</router-link>
                            </li>
                            <li class="breadcrumb-item text-white active" aria-current="page">{{ name }}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid" v-bind:src="require('../assets/call-center-reverse.png')" style="max-height: 300px;"/>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->
</template>

<script>
  export default {
    props: ['name']
  }
</script>

<style>
/*** Hero Header ***/
.hero-header {
    margin-top: -75px;
    background: url(../assets/bg-hero.png) center center no-repeat;
    background-size: cover;
    background-color: #1363C6;
}

.hero-header .breadcrumb-item+.breadcrumb-item::before {
    color: #F4F7FE;
}
</style>