<template>
    <NavBar/>
    <BannerSecondary name="A propos"/>
    <AboutCompany/>
    <FoundersDisplay/>
    <FooterComponent/>
  </template>
  
  <script>
  import NavBar from '../components/NavBar.vue'
  import BannerSecondary from '../components/BannerSecondary.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import FoundersDisplay from '../components/FoundersDisplay.vue'
  import AboutCompany from '../components/AboutCompany.vue'
  
  export default {
  components: {
    NavBar,
    BannerSecondary,
    FooterComponent,
    FoundersDisplay,
    AboutCompany
  }
}
</script>