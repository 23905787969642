<template>
    <NavBar/>
    <BannerSecondary name="Nos valeurs"/>
    <ValuesComponent/>
    <FooterComponent/>
  </template>
  
  <script>
  import NavBar from '../components/NavBar.vue'
  import BannerSecondary from '../components/BannerSecondary.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import ValuesComponent from '../components/ValuesComponent.vue'    
  
  export default {
  components: {
    NavBar,
    BannerSecondary,
    FooterComponent,
    ValuesComponent
  }
}
</script>