<template>
        <div class="container-fluid feature pt-5">
        <div class="container pt-5">
            <div class="row g-5">
                <div class="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                    <router-link class="btn btn-sm border rounded-pill text-white px-3 mb-3" to="/expertise">Pourquoi Nous</router-link>
                    <h1 class="text-white mb-4">Grandes entreprises, bénéficiez de notre équipe dans vos projets</h1>
                    <p class="text-light mb-4">Nous adaptons notre équipe aux missions proposées, permettant à vos projets de recevoir un apport ponctuel et des idées neuves.</p>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-feature rounded-circle me-3">
                            <font-awesome-icon :icon="['fas', 'check']"/>
                        </div>
                        <span>Apporteur de solutions innovantes</span>
                    </div>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-feature rounded-circle me-3">
                            <font-awesome-icon :icon="['fas', 'check']"/>
                        </div>
                        <span>Gestion de projets digitaux</span>
                    </div>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-feature rounded-circle me-3">
                            <font-awesome-icon :icon="['fas', 'check']"/>
                        </div>
                        <span>Réalisations techniques</span>
                    </div>
                    <div class="row g-4 pt-3">
                        <div class="col-sm-6">
                            <div class="d-flex rounded p-3" style="background: rgba(256, 256, 256, 0.1);">
                                <font-awesome-icon :icon="['fas', 'clock']" class="fa-3x text-white"/>
                                <div class="ms-3">
                                    <h2 class="text-white mb-0" data-toggle="counter-up">10</h2>
                                    <p class="text-white mb-0">Années d'expérience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <img class="img-fluid" v-bind:src="require('../assets/idea.png')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** Feature ***/
.feature {
    background: url(../assets/bg-hero.png) center center no-repeat;
    background-size: cover;
    background-color: #1363C6;
}

.text-feature {
    color: #1363C6;
}
</style>