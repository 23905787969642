<template>
    <NavBar/>
    <BannerSecondary name="Expertise"/>
    <SkillsComponent/>
    <FooterComponent/>
  </template>
  
<script>
  import NavBar from '../components/NavBar.vue'
  import BannerSecondary from '../components/BannerSecondary.vue'
  import FooterComponent from '../components/FooterComponent.vue' 
  import SkillsComponent from '../components/SkillsComponent.vue'
 
  
  export default {
  components: {
    NavBar,
    BannerSecondary,
    FooterComponent,
    SkillsComponent
  }
}
</script>