<template>
    <NavBar/>
    <BannerSecondary name="Cookies et RGPD"/>
    <CookiesComponent/>
    <FooterComponent/>
  </template>
  
  <script>
  import NavBar from '../components/NavBar.vue'
  import BannerSecondary from '../components/BannerSecondary.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import CookiesComponent from '../components/CookiesComponent.vue'
  
  export default {
  components: {
    NavBar,
    BannerSecondary,
    FooterComponent,
    CookiesComponent
  }
}
</script>