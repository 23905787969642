<template>
    <!-- About Start -->
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="about-img">
                        <img class="img-fluid" v-bind:src="require('../assets/img-work.jpg')"/>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="mb-4">Notre expertise</h1>
                    <p class="mb-4"> Forts de compétences diversifiées, nous proposons des services couvrant l'intégralité du spectre des métiers numériques, depuis la conception initiale jusqu'au déploiement final. Nous nous appliquons à créer des solutions sur mesure, adaptées à votre taille d'entreprise et votre business.</p>
                    <p class="mb-4">Pour en savoir plus, n'hésitez pas à nous contacter, nous nous ferons un plaisir d'en discuter avec vous.</p>
                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Réalisation & développement</h6>
                            <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Référencement (SEO)</h6>
                            <h6 class="mb-0"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Expérience utilisateur (UX)</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Sécurité</h6>
                            <h6 class="mb-3"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Méthodologies agiles</h6>
                            <h6 class="mb-0"><font-awesome-icon :icon="['fas', 'check']" class="text-block me-2"/>Conseils image</h6>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-4">
                        <router-link class="btn btn-primary rounded-pill px-4 me-3" to="/expertise">En savoir plus</router-link>
                        <!--
                        <a class="btn btn-outline-primary btn-square me-3" href=""><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><font-awesome-icon :icon="['fab', 'twitter']" /></a>
                        <a class="btn btn-outline-primary btn-square me-3" href=""><font-awesome-icon :icon="['fab', 'instagram']" /></a>
                        <a class="btn btn-outline-primary btn-square" href=""><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>


<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}

.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** About ***/
.about-img {
    position: relative;
    overflow: hidden;
}

.about-img::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/bg-about-img.png) top left no-repeat;
    background-size: contain;
}

.text-block {
    color: #1363C6;
}

.btn-outline-primary {
    color: #1363C6;
    border-color: #1363C6;
}

.btn-outline-primary:hover {
    background-color: #1363C6;
}
</style>