<template>
    <!-- About Start -->
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="mb-4 text-center">L'agence R42</h1>
                    <p class="mb-4">Fondée en 2023, l'agence digitale R42 a pour objectif de s'inscrire dans la durée. Pour cette raison, nous plaçons la satisfaction de nos clients comme principale mesure de notre activité, car nous pensons qu'un client satisfait c'est un partenariat durable qui s'installe. S'inscrire dans la durée, c'est également penser la société et son avenir, nous accordons de l'importance à nos valeurs autant dans la relation client que dans le developpement. N'hésitez pas à consulter nos valeurs pour en savoir plus.</p>
                    <p class="mb-4">D'où vient notre nom ? R42 c'est pour <i>Réponse 42</i>, peut-être connaissez-vous déjà cette référence culte provenant du roman <i>Le guide du voyageur intergalactique</i> de Douglas Adams. Dans cette oeuvre majeure de la science-fiction, <b>42</b> c'est la <b>R</b>éponse à toutes les plus grandes questions de l'univers, pour nous c'est la capacité à apporter des réponses à toutes vos questions. Nous ne sommes pas les premiers à utiliser ce nombre, on connaît notamment l'école 42 de développement informatique fondée par Xavier Niel.</p>
                    <p class="mb-4">C'est en prenant le temps de comprendre le métier de ses clients qu'il est possible de proposer des solutions adaptées et pertinentes. C'est pourquoi, nous prenons le temps de comprendre ce que vous faites pour rendre notre travail pragmatique et ajusté. Notre hantise ? Les projets inadaptés, démesurés, ne correspondant pas aux objectifs (formulés et non formulés). Nous ne disparaissons pas à la fin de nos projets, l'accompagnement et la transmission font partie de nos valeurs et de nos compétences.</p>
                    <p class="mb-4 text-center">Pour en savoir plus : <router-link to="/expertise">Nos compétences</router-link> et <router-link to="/valeurs">Nos valeurs</router-link></p>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>


<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}

.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** About ***/
.text-block {
    color: #1363C6;
}

.btn-outline-primary {
    color: #1363C6;
    border-color: #1363C6;
}

.btn-outline-primary:hover {
    background-color: #1363C6;
}
</style>