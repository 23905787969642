import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue.js'

import HomePage from './views/HomePage.vue'
import ContactPage from './views/ContactPage.vue'
import SkillsPage from './views/SkillsPage.vue'
import AboutPage from './views/AboutPage.vue'
import ValuesPage from './views/ValuesPage.vue'
import FAQPage from './views/FAQPage.vue'
import CookiesPolicy from './views/CookiesPolicy.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
/* Techno */
import { faHtml5, faSquareJs, faVuejs, faBootstrap, faPhp, faSymfony, faNodeJs, faWordpress, faCss3Alt, faNpm } from '@fortawesome/free-brands-svg-icons'

import { faAngleDown, faAngleUp, faCaretDown, faCaretLeft, faClock, faEarthAmericas, faEnvelope, faHandHoldingDollar, faLocationDot, faQuoteLeft, faTv, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import { faCopy, faFaceSmile, faImage } from '@fortawesome/free-regular-svg-icons'
import { faHandshake } from '@fortawesome/free-regular-svg-icons'

import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons'
import { faBatteryFull } from '@fortawesome/free-solid-svg-icons'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { faListCheck } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(
    faQuoteLeft,
    faStar,
    faInstagramSquare,
    faLinkedin,
    faFacebookSquare,
    faTwitterSquare,
    faUsers,
    faUser,
    faBars,
    faCirclePlus,
    faBatteryQuarter,
    faBatteryFull,
    faArrowUpRightFromSquare,
    faGlobe,
    faWrench,
    faUserShield,
    faListCheck,
    faCheck,
    faChevronDown,
    faChevronRight,
    faTwitter,
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faHtml5,
    faSquareJs,
    faVuejs,
    faBootstrap,
    faPhp,
    faSymfony,
    faNodeJs,
    faWordpress,
    faCss3Alt,
    faNpm,
    faFaceSmile,
    faHandshake,
    faMagnifyingGlass,
    faHandHoldingDollar,
    faEarthAmericas,
    faImage,
    faTv,
    faWifi,
    faLocationDot,
    faEnvelope,
    faCaretDown,
    faCaretLeft,
    faAngleUp,
    faAngleDown,
    faClock,
    faCopy)

const router = VueRouter.createRouter({
    scrollBehavior(to) {
        // scroll to anchor setup
        if (to.hash) {
            return {
              el: to.hash,
            }
          }
        return { top: 0 }
    },
    history: VueRouter.createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/contact',
            name: 'ContactPage',
            component: ContactPage
        },
        {
            path: '/expertise',
            name: 'SkillsPage',
            component: SkillsPage
        },
        {
            path: '/apropos',
            name: 'AboutPage',
            component: AboutPage
        },
        {
            path: '/valeurs',
            name: 'ValuesPage',
            component: ValuesPage
        },
        {
            path: '/faq',
            name: 'FAQPage',
            component: FAQPage
        },
        {
            path: '/cookies',
            name: 'CookiesPolicy',
            component: CookiesPolicy
        }
    ]
})


const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.mount('#app')