<template>
    <!-- About Start -->
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <h1 class="mb-4 text-center">Politique RGPD pour les cookies</h1>
                    <p class="mb-4"><b>Nous n'avez pas vu de bandeau pour accepter l'utilisation des cookies en naviguant sur notre site ?</b></p>
                    <p class="mb-4">C'est normal ! Nous n'utilisons pas les cookies sur notre site web. Nous nous soucions de vos données personnelles autant que des notres, c'est pourquoi, pour une navigation sans fonctionnalités le nécessitant, nous n'utilisons pas les cookies. Cela correspond aux valeurs que nous défendons, pour vous connaître nous ne vous espionnons pas, nous attendrons que vous preniez contact avec nous.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>


<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}
</style>