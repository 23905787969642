<template>
    <!-- Service Start -->
    <div class="container-fluid services-bg mt-5 py-5">
        <div class="container py-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <router-link class="btn btn-sm border rounded-pill text-primary-blue px-3 mb-3" to="/expertise">Nos Services</router-link>
                    <h1 class="mb-4">Nos services aux PME et indépendants</h1>
                    <p class="mb-4">Nos solutions sont adaptées aux petites et moyennes entreprises, ainsi qu'aux indépendants et commerçants.
                        Nous vous accompagnons pour comprendre votre métier et vos besoins afin de vous proposer des solutions digitales à la hauteurs de vos moyens et ambitions.
                        Notre format d'intervention s'adapte à tous les projets digitaux et au fonctionnement des petites équipes, autant pour le conseil que la réalisation.
                    </p>
                    <router-link class="btn btn-primary rounded-pill px-4" to="/expertise">En savoir plus</router-link>
                </div>
                <div class="col-lg-7">
                    <div class="row g-4">
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <font-awesome-icon :icon="['fas', 'globe']" size="2x"/>
                                        </div>
                                        <h5 class="mb-3">Réalisation de sites web</h5>
                                        <p>Dotez votre petite entreprise d'une plateforme web professionnelle qui attire l'attention et engage les visiteurs, grâce à notre expertise en conception de sites adaptés aux besoins des entrepreneurs.</p>
                                        <router-link class="btn px-3 mt-auto mx-auto" to="/expertise#anchor-dev">En savoir plus</router-link>
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <font-awesome-icon :icon="['fas', 'user-shield']" size="2x"/>
                                        </div>
                                        <h5 class="mb-3">Expertise digitale</h5>
                                        <p>
                                            Notre équipe vous accompagne sur l'intégralité de ce que recouvre un projet web.
                                            Sécurité de vos données et celles de vos clients, référencement sur les moteurs de recherche (SEO), expérience utilisateur (UX) et tunnels de conversion.
                                        </p>
                                        <router-link class="btn px-3 mt-auto mx-auto" to="/expertise#anchor-security">En savoir plus</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pt-md-4">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <font-awesome-icon :icon="['fas', 'wrench']" size="2x"/>
                                        </div>
                                        <h5 class="mb-3">Hébergement et maintenance</h5>
                                        <p>Libérez-vous des soucis techniques avec notre service d'hébergement et maintenance, vous offrant une plateforme web stable et performante.</p>
                                        <router-link class="btn px-3 mt-auto mx-auto" to="/expertise#anchor-host">En savoir plus</router-link>
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.7s">
                                    <div class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <font-awesome-icon :icon="['far', 'image']" size="2x"/>
                                        </div>
                                        <h5 class="mb-3">Travail de l'image</h5>
                                        <p>Améliorez l'identitié visuelle de votre entreprise grâce à nos conseils, alliant selon les besoins des recommandations d'interfaces et de visuels et même des séances photos.</p>
                                        <router-link class="btn px-3 mt-auto mx-auto" to="/expertise#anchor-image">En savoir plus</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->
</template>

<style>
h1,
h2,
h3,
.fw-bold {
    font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}

.text-primary-blue {
    color: #1363C6;
}

.services-bg {
    background: #F4F7FE;
}

.service-item {
    position: relative;
    padding: 45px 30px;
    background: #FFFFFF;
    transition: .5s;
}

.service-item:hover {
    background: #1363C6;
}

.service-item .service-icon {
    margin: 0 auto 20px auto;
    width: 90px;
    height: 90px;
    color: #1363C6;
    background: #F4F7FE;
    transition: .5s;
}

.service-item:hover .service-icon {
    background: #FFFFFF;
}

.service-item h5,
.service-item p {
    transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
    color: #FFFFFF;
}

.service-item a.btn {
    position: relative;
    display: flex;
    color: #1363C6;
    transition: .5s;
    z-index: 1;
}

.service-item:hover a.btn {
    color: #1363C6;
}

.service-item a.btn::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    border-radius: 35px;
    background: #F4F7FE;
    transition: .5s;
    z-index: -1;
}

.service-item:hover a.btn::before {
    width: 100%;
    background: #F4F7FE;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}
</style>