<template>
    <!-- Services-->
    <section class="page-section" id="services">
        <div class="container px-4 px-lg-5">
            <h2 class="text-center mt-0">Contactez-nous</h2>
            <hr class="divider" />
            <div class="text-center">
                N'hésitez pas à nous écrire à l'adresse e-mail ci-dessous pour toute question ou proposition de projet, nous nous ferons un plaisir de vous répondre.
            </div>
            <div class="text-center my-5">
                <span class="border bg-light p-4">contact@reponse42.com</span>
            </div>
            <div class="text-center">
                Nous espérons avoir de vos nouvelles bientôt !
            </div>
        </div>
    </section>
</template>

<style>
.page-section {
  padding: 8rem 0;
}

hr.divider {
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  background-color: #1363C6;
  opacity: 1;
}

.text-pimary-blue {
    color: #1363C6;
}
</style>