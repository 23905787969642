<template>
  <NavBar/>
  <BannerHome/>
  <ImageTextBlock/>
  <ServicesDisplay/>
  <TextImageBlock/>
  <HomeValuesBlock/>
  <TeamDisplay/>
  <FooterComponent/>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import BannerHome from '../components/BannerHome.vue'
import FooterComponent from '../components/FooterComponent.vue'
import TeamDisplay from '../components/TeamDisplay.vue'
import ServicesDisplay from '../components/ServicesDisplay.vue'
import ImageTextBlock from '../components/ImageTextBlock.vue'
import TextImageBlock from '../components/TextImageBlock.vue'
import HomeValuesBlock from '../components/HomeValuesBlock.vue'


export default {
components: {
  NavBar,
  BannerHome,
  FooterComponent,
  TeamDisplay,
  ServicesDisplay,
  ImageTextBlock,
  TextImageBlock,
  HomeValuesBlock
  }
}
</script>