<!-- Partie template du composant -->
<template>
  <!-- FAQs Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
        <h1 class="mb-4">Questions fréquentes</h1>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="accordion" id="accordionFAQ1">
            <div v-for="(item, index) in faqItemsFirstCol" :key="index" class="accordion-item wow fadeIn"
              data-wow-delay="0.1s">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#q0` + index" aria-expanded="false" :aria-controls="`q0` + index">
                  {{ item.question }}
                </button>
              </h2>
              <div :id="`q0` + index" class="accordion-collapse collapse"
                data-bs-parent="#accordionFAQ1">
                <div class="accordion-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="accordion" id="accordionFAQ2">
            <div v-for="(item, index) in faqItemsSecondCol" :key="index" class="accordion-item wow fadeIn"
              data-wow-delay="0.1s">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#q1` + index" aria-expanded="false" :aria-controls="`q1` + index">
                  {{ item.question }}
                </button>
              </h2>
              <div :id="`q1` + index" class="accordion-collapse collapse"
                data-bs-parent="#accordionFAQ1">
                <div class="accordion-body">
                  {{ item.answer }}
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FAQs Start -->
</template>
  
  <!-- Partie JS du composant -->
<script>
export default {
  data() {
    return {
      faqItemsFirstCol: [
        { question: 'Combien de temps va durer la création de mon site web ?', answer: 'Le temps de création d\'un site web est variable, il dépend de la complexité des besoins, du nombre de pages, etc... Cela peut aller de une semaine à plusieurs semaines voir mois (pour les sites autres que vitrines). N\'hésitez pas à nous contacter pour avoir une estimation.' },
        { question: 'Est-ce que mon site sera adapté aux mobiles ?', answer: 'Bien évidemment ! Le mobile représente aujourd\'hui plus de la moitié du trafic internet, il est donc indispensable de penser la navigation en pensant avec le mobile. De plus, les moteurs de recherches dont Google prennent majoritairement la navigation sur mobile comme critère pour le référencement, c\'est donc un impératif SEO.' },
        { question: 'Allez-vous maintenir mon site pour moi ?', answer: 'Si la maintenance est incluse, oui. Nous effectuons la maintenance pour garder les technologies à jour et éviter les failles de sécurité et les pannes au fur et à mesure que les normes évolues. En cas de pannes nous prenons en charge le problème afin de le résoudre dans les plus brefs délais et assurer un retour des services.' },
        { question: 'J\'ai un petit budget, avez-vous des options petits prix ?', answer: 'Oui, nous nous adaptons afin de rendre le coût des projets cohérents aux besoins et aux budgets. Cela ne signifie pas que tout est faisable à n\'importe quel prix, mais nous étudierons avec vous la meilleure façon de créer une solution répondant à vos objectifs. De cette façons nous mettons en place un projet avec un budget accessible à votre taille d\'entreprise.' },
        // Ajoutez d'autres questions-réponses selon vos besoins
      ],
      faqItemsSecondCol: [
        { question: 'Qui êtes-vous ?', answer: 'R42 est une jeune startup digitale fondée par deux passionnés des nouvelles technologies qui seront là pour vous accompagner dans vos projets. Faire appel à une jeune startup assure un suivi personnalisé de vos problématique, vous n\'êtes pas un client parmis des milliers, vous comptez pour nous !' },
        { question: 'Quels services proposez-vous ?', answer: 'Nous nous adaptons à vos besoins et objectifs. Bien souvent, cela dépend de la taille de votre structure, nous n\'en sommes pas tous au même stade dans l\'élaboration de notre stratégie digitale. Nos principales activités sont la création de site et services web, l\'hébergement et maintenance, le conseil et la prise de photo pour les visuels.' },
        { question: 'Comment pouvons-nous travailler ensemble ?', answer: 'Nous sommes basés en région parisienne, mais heureusement aujourd\'hui les technologies nous permettent de travailler en coordination où que vous soyez. Nous sommes adeptes de l\'agilité et nous savons nous adapter à votre rythme, tout en vous conseillant sur les meilleures façons de faire.' },
        { question: 'Et maintenant ?', answer: 'Le plus simple est de nous envoyer un mail, cela n\'engage à rien. Nous discuterons de votre projet pour déterminer comment et si nous pouvons vous aider.' },
        // Ajoutez d'autres questions-réponses selon vos besoins
      ],
    };
  },
};
</script>
  
  <!-- Partie CSS (ajoutée pour styliser l'accordéon) -->
<style scoped>
/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
  font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}

/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

/*** FAQs ***/
.accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.accordion .accordion-button {
  background: #F4F7FE;
  border-radius: 2px;
}

.accordion .accordion-button:not(.collapsed) {
  color: #FFFFFF;
  background: #1363C6;
  box-shadow: none;
}

.accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion .accordion-body {
  padding: 15px 0 0 0;
}</style>