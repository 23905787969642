<template>
    <NavBar/>
    <BannerSecondary name="Contactez-nous"/>
    <ContactComponent/>
    <FooterComponent/>
  </template>
  
  <script>
  import NavBar from '../components/NavBar.vue'
  import BannerSecondary from '../components/BannerSecondary.vue'
  import FooterComponent from '../components/FooterComponent.vue'
  import ContactComponent from '../components/ContactComponent.vue'  
  
  export default {
  components: {
    NavBar,
    BannerSecondary,
    FooterComponent,
    ContactComponent
  }
}
</script>