<template>
    <!-- Navbar Start -->
    <div class="container-fluid sticky-top">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-dark p-0">
                <router-link class="navbar-brand" to="/">
                    <h1 class="text-white">Agence R<span class="text-dark">.</span>42</h1>
                </router-link>
                <button type="button" class="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav ms-auto">
                        <router-link class="nav-item nav-link" to="/">Accueil</router-link>
                        <router-link class="nav-item nav-link" to="/expertise">Expertise</router-link>
                        <router-link class="nav-item nav-link" to="/valeurs">Nos valeurs</router-link>
                        <router-link class="nav-item nav-link" to="/apropos">A propos</router-link>
                        <router-link class="nav-item nav-link" to="/contact">Contact</router-link>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Navbar End -->
</template>

<style>
/*** Navbar ***/
.sticky-top {
    top: -150px;
    transition: .5s;
}

.navbar {
    padding: 11px 0 !important;
    height: 75px;
}

.navbar .navbar-nav .nav-link {
    margin-right: 35px;
    padding: 0;
    color: rgba(255, 255, 255, .7);
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: rgba(255, 255, 255, 1);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        padding: 0 15px;
        background: #1363C6;
    }

    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}
</style>